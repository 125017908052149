<template>
  <b-overlay :show="loading">
    <div>
      <list-table
        :show-search="false"
        :columns="tableColumns"
        :records="actionRequests"
        :search-filter.sync="search"
        :total-records="totalRecords"
        :show-filter-by-default="false"
      >
        <template #cell(company_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                :src="getValueFromSource(data, 'item.company_logo')"
                :text="sentenceCase(avatarText(`${data.item.company_name}`))"
                :variant="`light-primary`"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
            >
              {{ sentenceCase(getValueFromSource(data, 'item.company_name')) }}
            </b-link>
            <span class="text-muted">{{ data.item.region }},</span><br />
            <span class="text-muted">{{ data.item.country }}</span>
          </b-media>
        </template>

        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ getUserStatusText(data.item.status) }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'client-single-job', params: { job_id: data.item.job_id } }">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">View Job</span>
            </b-dropdown-item>
          </b-dropdown>

        </template>
      </list-table>
    </div>
  </b-overlay>
</template>

<script>
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ListTable from "@core/components/ListTable/ListTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { debounce, get } from "lodash";
import { formatDate } from "@core/utils/utils";
import { BOverlay, BLink, BButton, BModal, BRow, BCol, BTooltip, BAvatar, BMedia, BBadge, BDropdown, BDropdownItem, } from "bootstrap-vue";

const watchHandler = {
  handler(){
    this.debounceFetch()
  },
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.debounceFetch()
  },
  immediate: false
}

export default {
  name: "ListEventCenters",
  components: {
    BRow,
    BCol,
    BLink,
    BModal,
    BMedia,
    BBadge,
    BButton,
    vSelect,
    BAvatar,
    BTooltip,
    BOverlay,
    ListTable,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      actionRequests: [],

      search: "",
      debounceFetch: null,

      tableColumns: [
        { key: 'company_name', label: 'Company' },
        { key: 'job.title', label: 'Job Title' },
        { key: 'job.job_type', label: 'Job Type' },
        { key: 'status' },
        { key: 'created', sortable: false, formatter: val => `${formatDate(val, 'dd-MM-yy h:m:s a')}`, label: 'Date', class: 'width-200' },
        { key: 'actions' },
      ]
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    currentPage: watchHandler,
    statusFilter: watchHandler,
    search: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created() {
    this.debounceFetch = debounce(this.fetch, 500);

    const {
      page = 1,
      limit = this.perPage
    } = this.$route.query;

    this.perPage = +limit;
    this.currentPage = +page;
    this.fetch()
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        const query = {
          limit: this.perPage,
          page: this.currentPage,
        }
        await this.$router.push({ query }).catch(() => {});

        const request = await this.useJwt().clientService.fetchJobApplications(query);
        const { data, pagination } = request.data;

        this.totalRecords = pagination.totalRecords;
        this.perPage = pagination.limit;
        this.actionRequests = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async approve(id) {
      try {
        this.loading = true; 

        const result = await this.$swal({
          title: 'Approve request?',
          text: `Do you want to want to approve this request?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const form = new FormData();
        form.append('subject', 'contact');
        form.append('status', 'approved');
        await this.useJwt().clientService.respondCVAction(id, form);

        this.fetch();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `request sent successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async decline(id) {
      try {
        this.loading = true; 

        const result = await this.$swal({
          title: 'declined Request?',
          text: `Do you want to decline this request?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, proceed.',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        });

        if (!result.value) {
          return;
        }

        const form = new FormData();
        form.append('subject', 'contact');
        form.append('status', 'declined');
        await this.useJwt().clientService.respondCVAction(id, form);

        this.fetch();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success!',
            icon: 'AlertQuestionIcon',
            variant: 'success',
            text: `request sent successfully`
          },
        });
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
